import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import RegularPage from '../styles/regularPage';
import locationPage from '../styles/location-page.scss';

const StyledArticle = styled(RegularPage)`
	${locationPage}
`;

export const PortCarlingPageTemplate = ({
	title,
	featuredImage1,
	featuredImage2,
	featuredImage3,
	featuredImage4,
	featuredImage5,
	featuredImage6,
	featuredImage7,
	featuredImage8,
}) => (
	<StyledArticle>
		<section className="section intro-section">
			<div className="container">
				<div className="columns">
					<div className="column has-text-centered">
						<h1 className="bigger-serif-heading">{title}</h1>
						<p>What's In-Store this Season</p>
					</div>
				</div>
			</div>
		</section>
		<section className="images-section">
			<div className="columns is-multiline is-mobile">
				<div className="column is-12">
					<PreviewCompatibleImage imageInfo={featuredImage1} />
				</div>
				<div className="column is-12">
					<PreviewCompatibleImage imageInfo={featuredImage2} />
				</div>
				<div className="column is-4">
					<PreviewCompatibleImage imageInfo={featuredImage3} />
				</div>
				<div className="column is-8">
					<PreviewCompatibleImage imageInfo={featuredImage4} />
				</div>
				<div className="column is-8">
					<PreviewCompatibleImage imageInfo={featuredImage5} />
				</div>
				<div className="column is-4">
					<PreviewCompatibleImage imageInfo={featuredImage6} />
				</div>
				<div className="column is-4">
					<PreviewCompatibleImage imageInfo={featuredImage7} />
				</div>
				<div className="column is-8">
					<PreviewCompatibleImage imageInfo={featuredImage8} />
				</div>
			</div>
		</section>
		<section className="links-section">
			<div className="container">
				<div className="columns">
					<div className="column has-text-centered">
						<Link className="store-link" to="/contact-us#contact">
							Contact the Port Carling Store >
						</Link>
					</div>
					<div className="column"></div>
					<div className="column has-text-centered">
						<Link className="store-link" to="/toronto/">
							Visit the Toronto Store >
						</Link>
					</div>
				</div>
			</div>
		</section>
	</StyledArticle>
);

PortCarlingPageTemplate.propTypes = {
	title: PropTypes.string,
};

const PortCarlingPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const {
		featured_image_1,
		featured_image_2,
		featured_image_3,
		featured_image_4,
		featured_image_5,
		featured_image_6,
		featured_image_7,
		featured_image_8,
	} = frontmatter;

	return (
		<Layout background="light">
			<SEO title={frontmatter.title} />
			<PortCarlingPageTemplate
				title={frontmatter.title}
				featuredImage1={featured_image_1}
				featuredImage2={featured_image_2}
				featuredImage3={featured_image_3}
				featuredImage4={featured_image_4}
				featuredImage5={featured_image_5}
				featuredImage6={featured_image_6}
				featuredImage7={featured_image_7}
				featuredImage8={featured_image_8}
			/>
		</Layout>
	);
};

PortCarlingPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default PortCarlingPage;

export const portCarlingPageQuery = graphql`
	query PortCarlingPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				featured_image_1 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1600) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_2 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1600) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_3 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 850) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_4 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_5 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_6 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 850) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_7 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 850) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				featured_image_8 {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;
